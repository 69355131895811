import React from 'react';

// Components
import { StaticImage } from "gatsby-plugin-image"

// Hooks
import { useTranslation } from 'gatsby-plugin-react-i18next';

function Service({children, title, description}){
  return <div className="lg:flex w-full lg:grid lg:grid-cols-4 lg:gap-8">
          <div className="lg:h-full w-full lg:flex lg:justify-center ">
            {children}
          </div>
          <div className="col-span-3">
            <p className="text-xl text-[#354178] mb-2">{title}</p>
            <p className="text-lg lg:text-base text-slate-600">{description}</p>
          </div>
        </div>
}
export function Services(){
  const {t} = useTranslation("service");
  const maxWidth = 90;
  return <div className="mx-auto w-full max-w-7xl py-12 px-6 lg:p-12">
            <h3 className="lg:text-center font-bold text-gray-800 font-serif leading-[1.2] tracking-wide text-4xl">{t("services.title")}</h3>
            <h3 className="lg:mx-auto lg:text-center text-slate-500 my-4 max-w-2xl leading-[1.4]  tracking-wide text-lg lg:text-xl">{t("services.subtitle")}</h3>
            <div className="grid md:grid-cols-2 gap-12 my-20 lg:mx-auto max-w-5xl">
              <Service title={t("services.ml.title")} description={t("services.ml.description")}>
                <StaticImage placeholder="tracedSVG" src={"../../images/expertise_ml_icon_b.png"} alt={"twenty3"} style={{maxWidth: maxWidth}} className="max-h-40  w-full" objectFit="contain" imgClassName="h-full w-auto "/>
              </Service>
              <Service  title={t("services.data.title")} description={t("services.data.description")}>
                <StaticImage placeholder="tracedSVG" src={"../../images/expertise_ds_icon_b.png"} alt={"xpertsea"} style={{maxWidth: maxWidth}} className="max-h-40  w-full" objectFit="contain" imgClassName="h-full w-auto "/>
              </Service>
              <Service  title={t("services.eng.title")} description={t("services.eng.description")}>
                <StaticImage placeholder="tracedSVG" src={"../../images/expertise_data_icon_b.png"} alt={"curbside"} style={{maxWidth: maxWidth}} className="max-h-40  w-full" objectFit="contain" imgClassName="h-full w-auto "/>
              </Service>
              <Service  title={t("services.frontend.title")} description={t("services.frontend.description")}>
                <StaticImage placeholder="tracedSVG" src={"../../images/expertise_frontend_icon_b.png"} alt={"hortau"} style={{maxWidth: maxWidth}} className="max-h-40  w-full" objectFit="contain" imgClassName="h-full w-auto "/>
              </Service>
            </div>
         </div>
} 

