import React from "react";


// Hooks
import { useTranslation } from 'react-i18next';


import classnames from 'classnames';

// Components
import { ContactForm } from 'components/core/contact_form';
import { ConcaveCurve } from 'components/core/curves';

export function Contact({className, color="white"}){
  const {t} = useTranslation();
  return (
    <section className={className}>
      <div className="w-full mx-auto max-w-3xl">
            <div className={classnames("rounded shadow p-6 md:p-12", color==="white"? "bg-white": "bg-gray-100")} >
              <div className="text-left">
                <h2 className="font-serif text-2xl md:text-4xl font-bold">{t("contact.title")}</h2>
                <p className="text-slate-500 max-w-md  text-lg">{t("contact.subtitle")}</p>
              </div>
              <ContactForm info={"General contact form"}/>
        </div>
      </div> 
    </section>

    )
}

export function ContactCaseStudy(){
  return <div className=" bg-white ">
          <div className="relative ">
            <ConcaveCurve color="text-[#354178]"/>
            <div className="translate-y-1/3 px-3">
              <Contact color="gray"/>
            </div>
          </div>
          <div className=" w-full h-80 bg-[#354178]">
           </div>
         </div>
}

export function ContactWithCurve(){
  return <div className="bg-slate-50 py-8 md:py-16 relative">
          <div className="h-1/2 top-1/2 w-full absolute bg-[#354178]"/>
          <div className="h-1/2 top-0 w-full absolute ">
            <ConcaveCurve color="text-[#354178]"/>
          </div>
          <Contact className="relative md:px-3"/>
         </div>
}