import React from 'react';


// Hooks
import { useTranslation } from 'gatsby-plugin-react-i18next';

// Components
import { FiAward, FiSearch, FiCode, FiPackage } from "react-icons/fi";

function Step({title, description, Icon}){
  return <div className="md:flex py-6 text-slate-600">
          <Icon className="text-xl mb-2 md:mb-0 md:mt-1"/>
          <div className="md:ml-3">
            <h4 className="font-medium">{title}</h4>
            <p className="text-slate-600 ">{description}</p>
          </div>
         </div>
}

export function Process(){
  const {t} = useTranslation("service");
  return  <div className="mx-auto w-full max-w-6xl px-6 lg:p-12 lg:flex ">
            <div className="flex-1">
              <div className="w-full lg:max-w-md">
                <h3 className="font-bold text-gray-800 font-serif leading-[1.2] tracking-wide text-4xl">{t("process.title")}</h3>
                <p className="text-slate-600 my-6 leading-[1.4] tracking-wide text-lg">{t("process.description1")}</p>
                <p className="text-slate-600 my-6 leading-[1.4] tracking-wide text-lg">{t("process.description2")}</p>
              </div>
            </div>
            <div className="flex-1">
              <div className="w-full lg:ml-auto lg:max-w-lg bg-white shadow rounded-md  p-6 divide-y border-t-2 border-[#354178] ">
                <Step Icon={FiSearch} 
                    title={t("process.research.title")}
                    description={t("process.research.description")}/>
                <Step Icon={FiAward} 
                    title={t("process.stats.title")}
                    description={t("process.stats.description")}/>
                <Step Icon={FiCode} 
                    title={t("process.software.title")}
                    description={t("process.software.description")}/>
                  <Step Icon={FiPackage} 
                    title={t("process.integration.title")}
                    description={t("process.integration.description")}/>
              </div>
            </div>
          </div>
}