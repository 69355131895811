import React, {useState} from "react";
import { useTranslation } from 'react-i18next';

export function ContactForm({info}={}){
  const [status, setStatus] = useState();
  const { t } = useTranslation();

  const submitForm = (ev)=> {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        setStatus("SUCCESS");
      } else {
        if (status==="ERROR"){
          setStatus("ERRORTWICE")
        }
        else {
          setStatus("ERROR");
        }
      }
    };
    xhr.send(data);
  }
  return ( <form onSubmit={submitForm} 
                 action={`https://formspree.io/maylyrgr`}
                 method="POST">
                <input type="hidden" id="extraInfo" name="extraInfo" value={info}/>
              <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8 mt-6">
                <div className="">
                    <label htmlFor="first-name" className="block text-slate-500 font-medium">{t("contact.firstname")}</label>
                    <div className="mt-1">
                      <input type="text" name="firstname" id="first-name" autoComplete="given-name" className="block w-full rounded-md border border-gray-300 py-3 px-4 focus:border-[#354178]"/>
                    </div>
                </div>
               <div>
                  <label htmlFor="last-name" className="block text-slate-500 font-medium">{t("contact.lastname")}</label>
                  <div className="mt-1">
                    <input type="text" name="lastname" id="last-name" autoComplete="family-name" className="block w-full rounded-md border border-gray-300 py-3 px-4 focus:border-[#354178]"/>
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label htmlFor="email" className="block text-slate-500 font-medium">{t("contact.email")}</label>
                  <div className="mt-1">
                    <input id="email" name="email" type="email" autoComplete="email" className="block w-full rounded-md border border-gray-300 py-3 px-4 focus:border-[#354178]"/>
                  </div>
                </div>
                <div className="sm:col-span-2">
                <label htmlFor="message" className="block text-slate-500 font-medium">{t("contact.message")}</label>
                <div className="mt-1">
                  <textarea id="msg" name="msg" rows="4" className="block w-full rounded-md border border-gray-300 py-3 px-4 focus:border-[#354178]"></textarea>
                </div>
              </div>
                <div className="col-span-full">
                  <div className="mt-6">  
                    {status === "ERROR" && <p className="text-red-500 font-bold">{t("contact.error")}</p>}
                    {status === "ERRORTWICE" && <p className="text-red-500 font-bold">{t("contact.error-twice")}</p>}
                    {status === "SUCCESS" ? <p className="text-green-500 font-bold">{t("contact.success")}</p> : 
                    <button className="block text-center w-full bg-gradient-to-r from-[#354178] to-[#566BC4]  text-white py-3 rounded shadow hover:opacity-80 hover:scale-[1.02] transition-transform" type="submit">
                      {t("contact.submit")}
                    </button>}
                  </div>
                </div>
              </div>
            </form>)
}
