import React from 'react';

// Hooks
import { useTranslation } from 'gatsby-plugin-react-i18next';

function Testimony({name, role, content}){
  return <div className="flex flex-col">
        <div className="bg-white rounded-lg p-6 max-h-min ">
          <p className="text-slate-600  mb-5">{content}</p>
          <h4 className="font-medium">{name}</h4>
          <p className="text-slate-600">{role}</p>
        </div>
        </div>
}

export function Testimonies(){
  const {t} = useTranslation("service");
  return  <div className="bg-slate-50">
          <div className="mx-auto w-full max-w-7xl py-12 px-3 md:px-6 lg:p-12 ">
            <h3 className="text-center font-bold text-gray-800 font-serif leading-[1.2] tracking-wide text-4xl">{t("testimony.title")}</h3>
            <h3 className="mx-auto text-center text-slate-400 my-4 max-w-2xl font-light leading-[1.4]  tracking-wide text-xl">{t("testimony.subtitle")}</h3>
            <div className="grid grid-cols-1 gap-6 lg:gap-8 sm:grid-cols-2 lg:grid-cols-3">
              <div className="flex flex-col space-y-8">
                <Testimony name={t("testimony.voxmedia.name")}
                          role={t("testimony.voxmedia.role")}
                          content={t("testimony.voxmedia.content")}/>
                <Testimony name={t("testimony.hortau.name")}
                          role={t("testimony.hortau.role")}
                          content={t("testimony.hortau.content")}/>
              </div>
              <div className="flex flex-col space-y-8 hidden lg:block">
                <Testimony name={t("testimony.dam.name")}
                        role={t("testimony.dam.role")}
                        content={t("testimony.dam.content")}/>
                <Testimony name={t("testimony.xpertsea.name")}
                          role={t("testimony.xpertsea.role")}
                          content={t("testimony.xpertsea.content")}/>
              </div>
              
              <div className="flex flex-col space-y-8 hidden lg:block">
                <Testimony name={t("testimony.ondia.name")}
                          role={t("testimony.ondia.role")}
                          content={t("testimony.ondia.content")}/>
              </div>
            </div>
         </div>
         </div>
}